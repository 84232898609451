import request from "@/libs/request"

// 根据手机号登录注册
//  POST
// /jeecg-boot/app/api/authLogin
export function authLogin(data) {
    return request({
        url: '/app/api/authLogin',
        method: 'post',
        data
    })
}

// 获取用户信息
//  POST
// /jeecg-boot/app/api/memberInfo
export function memberInfo(data) {
    return request({
        url: '/app/api/memberInfo',
        method: 'post',
        data
    })
}


// 查询移动积分
//  POST
// /jeecg-boot/app/api/queryCmccBalance
export function queryCmccBalance(data) {
    return request({
        url: '/app/api/queryCmccBalance',
        method: 'post',
        data
    })
}


// 获取商品接口
//  POST
// /jeecg-boot/app/api/getProduct
export function getProduct(data) {
    return request({
        url: '/app/api/getProduct',
        method: 'post',
        data
    })
}

// 下单接口
//  POST
// /jeecg-boot/app/api/placeOrder
export function placeOrder(data) {
    return request({
        url: '/app/api/placeOrder',
        method: 'post',
        data
    })
}

// 支付接口
//  POST
// /jeecg-boot/app/api/dectOrder
export function dectOrder(data) {
    return request({
        url: '/app/api/dectOrder',
        method: 'post',
        data
    })
}


// 获取短信接口
//  POST
// /jeecg-boot/app/api/sendCmccSms
export function sendCmccSms(data) {
    return request({
        url: '/app/api/sendCmccSms',
        method: 'post',
        data
    })
}